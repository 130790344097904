body {
  font-family: Arial, sans-serif;
}

header {
  background-color: #333;
  color: white;
  padding: 10px 0;
}

header nav ul {
  list-style: none;
  padding: 0;
}

header nav ul li {
  display: inline;
  margin: 0 10px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.project-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
}
